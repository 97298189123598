import BaseModuleRepositoryInterface from "@/repositories/interfaces/BaseModuleRepositoryInterface"

const bind = (repositoryName, Interface) => {
  return {
    ...Object.keys(Interface).reduce((prev, method) => {
      const resolveableMethod = async (...args) => {
        const repository = await import(`@/repositories/${repositoryName}`);
        return repository.default[method](...args);
      };
      return { ...prev, [method]: resolveableMethod };
    }, {})
  };
};


export default {
  authRepository: bind("AuthRepository", BaseModuleRepositoryInterface),
  profileRepository: bind("ProfileRepository", BaseModuleRepositoryInterface),
  planEnterprisePriceRepository: bind("PlanEnterprisePriceRepository", BaseModuleRepositoryInterface),
  ownerRepository: bind("OwnerRepository", BaseModuleRepositoryInterface),
  enterpriseRepository: bind("EnterpriseRepository", BaseModuleRepositoryInterface),
  employeeRepository: bind("EmployeeRepository", BaseModuleRepositoryInterface),
  transportationRepository: bind("TransportationRepository", BaseModuleRepositoryInterface),
  rowRepository: bind("RowRepository", BaseModuleRepositoryInterface),
  chairRepository: bind("ChairRepository", BaseModuleRepositoryInterface),
  planClientRepository: bind("PlanClientRepository", BaseModuleRepositoryInterface),
  travelRepository: bind("TravelRepository", BaseModuleRepositoryInterface),
  saleRepository: bind("SaleRepository", BaseModuleRepositoryInterface),
  paymentRepository: bind("PaymentRepository", BaseModuleRepositoryInterface),
  userRepository: bind("UserRepository", BaseModuleRepositoryInterface),
  myEnterpriseRepository: bind("MyEnterpriseRepository", BaseModuleRepositoryInterface),
  typeTransportationRepository: bind("TypeTransportationRepository", BaseModuleRepositoryInterface),
  accompanistRepository: bind("AccompanistRepository", BaseModuleRepositoryInterface),
  chairSaleRepository: bind("ChairSaleRepository", BaseModuleRepositoryInterface),
  babyRepository: bind("BabyRepository", BaseModuleRepositoryInterface),
  clientRepository: bind("ClientRepository", BaseModuleRepositoryInterface),
  registerAccompanistRepository: bind("RegisterAccompanistRepository", BaseModuleRepositoryInterface),
  liquidationRepository: bind("LiquidationRepository", BaseModuleRepositoryInterface),
  variableLiquidationRepository: bind("VariableLiquidationRepository", BaseModuleRepositoryInterface),
  historyPlanRepository: bind("HistoryPlanRepository", BaseModuleRepositoryInterface),
  geographyRepository: bind("GeographyRepository", BaseModuleRepositoryInterface),
  moduleRepository: bind("ModuleRepository", BaseModuleRepositoryInterface),
  tutorialRepository: bind("TutorialRepository", BaseModuleRepositoryInterface),
}