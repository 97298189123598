<template>
  <section class="sideBar scroll__menu--admin open-menu">
    <div class="sideBar-ct">
      <!-- sideBar-title -->
      <div class="text-center sideBar-title">
        <div class="btn__closed--menu">
          <b-icon-backspace-fill @click.prevent="btnClosed" />
        </div>
        <router-link to="/">Guiia Tour</router-link>
      </div>
      <!-- SideBar - User info -->
      <div class="sideBar-UserInfo">
        <h4 class="text-center">{{ getUser.fullname }}</h4>
        <h5 class="text-center">{{ getUser.profile.name }}</h5>
        <div class="name__business--admin">{{ getEnterprise ? getEnterprise.name : 'Guiia Tour' }}</div>
      </div>
      <!-- SideBar - Menu -->
      <div class="title__menu text-center">Menú</div>
      <div class="sideBar-menu">
        <div class="item__menu" @click="btnClosed">
          <router-link to="/dashboard">
            <span class="title__item">Inicio</span>
          </router-link>
        </div>
        <div v-for="menu in menus" :key="menu.path">
          <div v-if="hasPermission(menu.moduleUuid, menu.typeMenu)"  class="item__menu" @click.prevent="btnClosed">
            <router-link :to="menu.path">
              <span class="title__item">{{ menu.name }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BIconBackspaceFill,
} from "bootstrap-vue";
import { actionLayout } from "@/mixins/layout";
import { mapGetters } from "vuex";
import PermissionAction from "@/mixins/PermissionAction";
import menus from "./menus.json"

export default {
  name: "Sidebar",
  components: {
    BIconBackspaceFill,
  },
  mixins: [actionLayout, PermissionAction],

  data(){
    return {
      user: {},
      menus: menus
    }
  },
  computed: {
    ...mapGetters("user", ["getUser", "getEnterprise"])
  },
  methods: {
    logout(){
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      localStorage.removeItem("authenticated");

      this.$router.push("/login");
    }
  },
};
</script>

<style>
@import "./sidebar.css";
</style>