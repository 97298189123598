<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-2">
      <b-col lg="12" md="12" sm="12" class="mb-3">
         <label for="name" class="text__ mb-0">
           Introduce el correo que tienes asociado a tu cuenta.
           Te envíaremos un email para cambiar tú
           contraseña.
         </label>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="name" class="label_input">Correo electrónico</label>
          <b-form-input
            id="name"
            size="sm"
            type="email"
            placeholder="Correo electrónico"
            v-model="formData.email"
            @blur="$v.formData.email.$touch()"
          />
          <span
            v-if="$v.formData.email.$error && !$v.formData.email.email"
            class="help-block text-danger text_error"
          >Ingrese una dirección de correo válida.</span>
          <span
            v-else
            class="help-block text-danger text_error"
          >&nbsp;</span>
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Enviar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import { required, email } from "vuelidate/lib/validators";
import messageMixin from "@/mixins/messageMixin";

export default {
  inject: ["authRepository"],
  mixins: [messageMixin],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
  },
  data() {
    return {
      profiles: [],
      isBusy: false,
      formData: {
        email: '',
      },
    };
  },
  validations: {
    formData: {
      email: {
        required,
        email,
      },
    },
  },
  async mounted() {},
  methods: {
    async saveData() {
      const me = this;
      try {
        me.isBusy = true;
        await me.authRepository.passwordRecovery(me.formData);
        me.$emit('sendEmail');
      } catch (error) {
        // console.log(error)
        const { message } = error.data;
        me.errorSave(message)
      } finally {
        me.isBusy = false;
      }
    },
    errorSave(message) {
      const me = this

      let title = 'Error al guardar registro' 
      let messageErr = 'Registro no guardado.';

      if (message == 'User not registered') {
        title = 'Error Correo';
        messageErr = 'Correo electrónico no esta registrado.'
      }
      me.messageError(title, messageErr);
    },
  },
};
</script>

<style></style>
