import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/app/auth/login/Login";
import profiles from "@/config/profiles";
import Layout from "@/components/layout/Layout";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/inicio",
      component: () =>
        import(
          /* webpackChunkName: "home"*/ "@/app/home/Home"
        ),
    },
    {
      path: "/inicio",
      name: "home",
      component: () =>
        import(
          /* webpackChunkName: "home"*/ "@/app/home/Home"
        ),
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/registro",
      name: "register",
      component: () =>
        import(
          /* webpackChunkName: "register"*/ "@/app/auth/register/Register"
        ),
    },
    {
      path: "/change-password",
      name: "changePassword",
      component: () =>
        import(
          /* webpackChunkName: "changePassword"*/ "@/app/changePassword/ChangePassword"
        ),
    },
    {
      path: "/app",
      name: "Layout",
      component: Layout,
      meta: {
        requireAuth: true,
      },
      children: [],
    },
    // Register de Accompanist
    {
      path: "/registro-de-pasajeros",
      name: "register-accompanist",
      component: () =>
        import(
          /* webpackChunkName: "register-accompanist"*/ "@/app/registerAccompanist/Home"
        ),
    },
    // Ruta errors
    // {
    //   path: '*',
    //   name:'error', 
    //   component: () =>
    //     import(/* webpackChunkName: "error"*/ "@/app/errors/Error404")
    // }
  ],
});

export async function beforeEach(to, from, next) {
  if (localStorage.getItem("user")) {
    let user = JSON.parse(localStorage.getItem("user"));
    const { uuid } = user.profile;
    let routes = profiles[uuid].routes;
    
    routes.forEach((route) => {
      if (
        router.getRoutes().findIndex((item) => item.name == route.name) === -1
      ) {
        router.addRoute("Layout", route);
      }
    });
  }

  if (router.getRoutes().findIndex((route) => route.name === to.name) > -1) {
    if (to.matched.some((record) => record.meta.requireAuth)) {
      if (!localStorage.getItem("access_token")) {
        next({
          name: "login",
        });
      } else {
        next();
      }
    } else {
      if (
        localStorage.getItem("authenticated") === "true" &&
        (to.name === "login" || to.path === "/" || to.path === "/inicio")
      ) {
        next("/dashboard");
      } else {
        next();
      }
    }
  } else {
    if (!localStorage.getItem("access_token")) {
      next({
        name: "home",
      });
    } else {
      next(to.path);
    }
  }
}

// router.beforeEach((to,from,next) => {

//   if(to.matched.some(route => route.meta.requireAuth)){
//     if(localStorage.getItem('access_token')) return next();
//     return next('/login');
//   }

//   if (
//     localStorage.getItem("authenticated") === "true" &&
//     to.name === "login" || to.path === "/"
//   ) {
//     next('/dashboard');
//   } else {
//     next();
//   }

//   next();
// });

router.beforeEach((to, from, next) => beforeEach(to, from, next));

export default router;
