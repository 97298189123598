<template>
  <b-row class="mb-2">
    <b-col lg="12" md="12" sm="12" class="mb-1">
        <label for="name" class="text__ mb-0">
          Se ha enviando un correo para que puedas restaurar tu contraseña.
          Recuerda que ese link vence en 15 minutos. Y tendrás que generar uno nuevo.
        </label>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
} from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
  },
}
</script>

