<template>
  <div>
    <nav class="Navbar">
      <div class="header__container--admin">
        <div class="left__area">
          <b-button class="btn__bars--admin waves" @click="btnBars">
            <b-icon-list />
          </b-button>
        </div>
        <div class="right__area">
          <b-button class="user__profile--nav" sm @click.prevent="panelUser('open')">
            <b-icon-person-circle />
          </b-button>
        </div>
      </div>
    </nav>
    <div class="panel__user p-1" :class="{ active: isActive }">
      <div class="d-flex justify-content-end mb-4">
        <div class="btn__closed" @click.prevent="panelUser('close')">
          <b-icon-x font-scale="2" />
        </div>
      </div>
      <div class="text-center">
        <div class="profile__name mb-1">{{ getUser.fullname }}</div>
        <div class="profile__email mb-2">{{ getUser.username }}</div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="profile__button text-center" @click.prevent="logout">
          <b-icon-box-arrow-left />
          Cerrar sesión
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BIconList,
  BIconPersonCircle,
  BButton,
  BIconBoxArrowLeft,
  BIconX,
} from "bootstrap-vue";
import { actionLayout } from "@/mixins/layout";
import messageMixin from "@/mixins/messageMixin";
import { mapGetters } from "vuex";

export default {
  inject: ["authRepository"],
  components: {
    BIconList,
    BIconPersonCircle,
    BIconBoxArrowLeft,
    BIconX,
    BButton,
  },
  mixins: [actionLayout, messageMixin],
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
  },
  methods: {
    panelUser(type) {
      type == "open" ? (this.isActive = true) : (this.isActive = false);
    },
    async logout() {
      const me = this;
      try {
        await me.authRepository.logout({
          username: me.getUser.username,
        });
        localStorage.clear();
        me.$router.push("/login");
      } catch (error) {
        //console.log(error);
        me.messageError("Error al cerrar sesión", "Vuelve a intentarlo");
      }
    },
  },
};
</script>

<style>
@import "./header.css";
</style>
