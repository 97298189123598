export default
{
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : "",
    enterprise: JSON.parse(localStorage.getItem("enterprise")) ? JSON.parse(localStorage.getItem("enterprise")) : "",
    isModal: localStorage.getItem("isModal") ? localStorage.getItem("isModal") : false,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getEnterprise(state) {
      return state.enterprise;
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setEnterprise(state, enterprise) {
      state.enterprise = enterprise
    },
    setIsModal(state, isModal) {
      state.isModal = isModal
    }
  },
  actions: {
    loadUser({ commit }, payload) {
      let enterprise =  null
      const { user, token } = payload;
      user.enterprise ? enterprise = user.enterprise : null
      delete user.enterprise;
      
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("enterprise", JSON.stringify(enterprise));
      localStorage.setItem("isModal", user.isModal);
      localStorage.setItem("access_token", token);
      localStorage.setItem("authenticated", true);

      commit("setUser", user);
      commit("setEnterprise", enterprise);
      commit("setIsModal", user.isModal);
    },
    loadEnterprise({ commit }, payload) {
      localStorage.setItem("enterprise", JSON.stringify(payload));
      commit("setEnterprise", payload)
    },
    updateUser({ commit }, payload) {
      localStorage.setItem("user", JSON.stringify(payload));
      commit("setUser", payload);
    },

    updateIsModal({ commit }, payload) {
      localStorage.setItem("isModal", payload);
      commit("setIsModal", payload);
    }
  },
}