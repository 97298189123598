export default {
  getAll() {},
  getAllByOtherEnterprise() {},
  getAllEnterpriseByAdmin() {},
  getAllTravelEnterpriseByDate() {},
  getAllSaleByDate() {},
  getAllTravelByDate() {},
  getAllByTravel() {},
  getAllPayments() {},
  getAllByType() {},
  getAllByModule() {},
  getDepartments() {},
  getMunicipalitiesByDepartment() {},
  generarReport() {},
  generarReportPilot() {},
  find() {},
  search() {},
  save() {},
  saveImage() {},
  delete() {},
  update() {},
  login() {},
  register() {},
  logout() {},
  moveSale() {},
  moveSaleEnteprise() {},
  moveSaleEnterpriseNotExist() {},
  passwordRecovery() {},
  changePassword() {},
  changePasswordFistSession() {}
};