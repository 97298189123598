<template>
  <footer class="footer-home">
    <router-link to="/home">
      <div class="content-img">
        <img src="@/assets/images/logologin.png" alt="Guiia Tour" />
      </div>
    </router-link>
    <p class="text-center">
      &copy;{{ currentYear }} <strong>Guiia Tour</strong> - Todos los derechos
      reservados
    </p>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="css">
.footer-home {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  background-color: white;
}

.footer-home .content-img {
  margin-bottom: 10px;
}
.footer-home .content-img img {
  width: 200px;
}
</style>
