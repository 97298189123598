export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard"*/ "@/app/dashboard/Dashboard2"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/propietarios",
    name: "owner",
    component: () =>
      import(/* webpackChunkName: "owner"*/ "@/app/owner/Owner"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/empresas",
    name: "enterprise",
    component: () =>
      import(/* webpackChunkName: "enterprise"*/ "@/app/enterprise/Enterprise"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/mi-perfil",
    name: "myprofile",
    component: () =>
      import(/* webpackChunkName: "myprofile"*/ "@/app/myProfile/MyProfile"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/tutoriales",
    name: "tutorials",
    component: () =>
      import(/* webpackChunkName: "tutorials"*/ "@/app/tutorial/Tutorials"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/tutorial/:uuid",
    name: "tutorial",
    component: () =>
      import(/* webpackChunkName: "tutorial"*/ "@/app/tutorial/Tutorial"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/ver-video/:uuid",
    name: "watchVideo",
    component: () =>
      import(/* webpackChunkName: "watchVideo"*/ "@/app/tutorial/WatchVideo"),
    meta: {
      requireAuth: true,
    },
  }
]