import store from "@/store"

export default {
  methods: {
    hasPermission(moduleUuid, permission) {
      const modulePermission = store.getters['user/getUser'].modules;

      const modules = [];

      modulePermission.forEach(el => {
        modules.push({
          moduleUuid: el.moduleUuid,
          permissions: el.permission
        });
      });
      const allowedModule = modules.find(el => el.moduleUuid == moduleUuid);
      
      return allowedModule && allowedModule.permissions.includes(permission)
    },
    hasPermissionModule(moduleUuid) {
      const modulePermission = store.getters['user/getUser'].modules
      const modules = [];

      modulePermission.forEach(el => {
        modules.push({
          moduleUuid: el.moduleUuid,
        });
      });
      return modules.find(el => el.moduleUuid == moduleUuid);
    }
  }
}