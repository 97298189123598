<template>
  <div id="app">
    <router-view/>
    <expired-token-modal />
    <session-finish-modal />
    <renovation-plan-enterprise-modal />
  </div>
</template>
<script>
import ExpiredTokenModal from "./components/modal/ExpiredTokenModal";
import SessionFinishModal from "./components/modal/SessionFinishModal";
import RenovationPlanEnterpriseModal from "./components/modal/RenovationPlanEnterpriseModal";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    ExpiredTokenModal,
    SessionFinishModal,
    RenovationPlanEnterpriseModal,
  },
  computed: {
    ...mapState("user", ["isModal", "user"]),
  },
  mounted() {
    const me = this;
    if (me.isModal === "true") me.$bvModal.show("renovation-plan-modal");

    if (me.user?.isFisrtSession) me.$router.push({ name: "changePassword"});
  }
}
</script>
<style>
  @import './assets/css/admin.css';
</style>
