//Routes
import superadminRoutes from "./superadminRoutes";
import adminRoutes from "./adminRoutes";
import auxiliarRoutes from "./auxiliarRoutes";
import vendedorRoutes from "./vendedorRoutes";

const profiles = {
  "cda783bb-338c-41b4-912b-10c3d2c247e3": {
    routes: superadminRoutes,
  },
  "bcaa1cc2-72f3-4183-a36b-03e4c7d845ba": {
    routes: adminRoutes,
  },
  "cmm243bb-270c-41m4-992b-30c4d2c247b0": {
    routes: adminRoutes,
  },
  "cbm783bb-325c-21b4-912b-24c3d0m547e3": {
    routes: auxiliarRoutes,
  },
  "mam783bb-275c-21m4-912b-24c3d0m547e2": {
    routes: vendedorRoutes,
  }
}

export default profiles;