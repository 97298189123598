export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard"*/ "@/app/dashboard/Dashboard"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/mi-perfil",
    name: "myprofile",
    component: () =>
      import(/* webpackChunkName: "myprofile"*/ "@/app/myProfile/MyProfile"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/tours",
    name: "travel",
    component: () =>
      import(/* webpackChunkName: "travel"*/ "@/app/travel/Travel"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/tours/tickets/:travelUuid",
    name: "ticket",
    component: () =>
      import(/* webpackChunkName: "ticket"*/ "@/app/ticket/Ticket"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/tutoriales",
    name: "tutorials",
    component: () =>
      import(/* webpackChunkName: "tutorials"*/ "@/app/tutorial/Tutorials"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/tutorial/:uuid",
    name: "tutorial",
    component: () =>
      import(/* webpackChunkName: "tutorial"*/ "@/app/tutorial/Tutorial"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/ver-video/:uuid",
    name: "watchVideo",
    component: () =>
      import(/* webpackChunkName: "watchVideo"*/ "@/app/tutorial/WatchVideo"),
    meta: {
      requireAuth: true,
    },
  }
]