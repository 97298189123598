<template>
  <nav class="navbar-home">
    <router-link to="/home">
      <div class="content-img">
        <img src="@/assets/images/logo.png" alt="Guiia Tour">
      </div>
    </router-link>
    <div>
      <router-link class="btn-nav btn-login" to="/login">Ingresar</router-link>
      <router-link class="btn-nav btn-register" to="/registro">Empieza gratis</router-link>
    </div>
  </nav>
</template>

<script>
  export default {}
</script>

<style lang="css">
.navbar-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  padding: 0 30px;
}

.btn-nav {
  padding: 8px;
  border-radius: .6rem;
  text-decoration: none;
  font-size: 18px;
  text-decoration: none !important;
}

.btn-nav:hover {
  color: white !important;
}

.btn-login {
  background-color: white;
  border: 1px solid#5f44dd;
  color: black;
  margin-right: 15px;
}

.btn-login:hover {
  color: var(--black-color) !important;
}

.btn-register {
  background-image: linear-gradient(to right, #123fd6, #5f44dd, #8949e3, #ac4fe8, #cc57ec);
  color: white;
  font-weight: 500;
}
</style>