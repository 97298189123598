import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'vue-select/dist/vue-select.css'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Vuelidate from "vuelidate";
import RepositoryProvider from "@/providers/RepositoryProvider";
// import VueSocketIO from 'vue-socket.io'
// import SocketIO from 'socket.io-client';

Vue.config.productionTip = false;

Vue.use(Vuelidate);
// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: SocketIO(process.env.VUE_APP_API_URL),
// }));
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin, {
	BModal: { noCloseOnBackdrop: true, noCloseOnEsc: true },
})

new Vue({
  router,
  provide: RepositoryProvider,
  store,
  render: (h) => h(App),
}).$mount("#app");
