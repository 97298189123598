<template>
  <b-modal id="renovation-plan-modal" hide-footer centered size="md">
    <template v-slot:modal-header>
      <h5>{{ user?.dataSms?.discount > 0 ? '¡Renueva tu suscripción con un descuento especial!' : '¡Renueva tu suscripción!'}}</h5>
      <button class="btn__close" @click.prevent="close()">
        <b-icon-x font-scale="2" />
      </button>
    </template>
    <b-container class="container-message-renovation mt-3">
      <b-row v-if="user?.dataSms?.discount > 0">
        <b-col cols="12">
          <p>
            !Hola <strong>{{ user?.dataSms?.names }}</strong>, {{user?.dataSms?.enterprise}}!
          </p>
          <p>
            Esperamos que estés disfrutando de nuestro servicio tanto como nosotros disfrutamos brindártelo. Queremos asegurarnos de que continúes disfrutando de todos los beneficios de nuestra plataforma Guiia Tour.
          </p>
          <p>
            Nos gustaría recordarte que tu suscripción está próxima a vencer el <strong>{{user?.dataSms?.final_date }}</strong>. Pero ¡no te preocupes! Estamos aquí para hacer que la renovación sea aún más gratificante para ti.
          </p>
          <p>
            Si decides renovar tu suscripción hoy <strong>{{user?.dataSms?.currentDate}}</strong>, te ofrecemos un descuento especial del <strong>{{user?.dataSms?.percentage}}%</strong>. Este es nuestro agradecimiento por tu continua confianza en nosotros.
          </p>
          <p>
            Recuerda que al renovar anticipadamente, no solo garantizas la continuidad de tu acceso sin interrupciones, sino que también te beneficias de un precio reducido.
          </p>
          <p>
            <ul class="list-detalle-plan">
              <li><strong>Plan:</strong> {{user?.dataSms?.namePlan}}</li>
              <li><strong>Precio:</strong> {{formatPrice(user?.dataSms?.price)}}</li>
              <li><strong>Descuento:</strong> {{formatPrice(user?.dataSms?.discount)}}</li>
              <li><strong>Total a pagar:</strong> {{formatPrice(user?.dataSms?.total)}}</li>
            </ul>
          </p>
          <p>
            <strong>Métodos de pago</strong>
            <ul>
              <li>Ahorros Bancolombia: 007-363929-17</li>
              <li>Nequi: 3007623748</li>
            </ul>
          </p>
          <p>
            <strong>Por favor</strong>, debes enviar una foto del comprobante de pago al <strong>300 7492396</strong>, para que podamos verificar el pago.
          </p>
          <p>
            ¡No dejes pasar esta oportunidad! Renueva ahora y sigue disfrutando de todos los beneficios que ofrecemos.
          </p>
          <p>
            Gracias por ser parte de nuestra comunidad.
          </p>
          <p>
            Atentamente, Equipo Guiia Tour
          </p>
          <p>
            Si tienes alguna pregunta o necesitas ayuda con el proceso de renovación, no dudes en ponerte en contacto con nuestro equipo de soporte al <strong>300 7492396</strong> Estamos aquí para ayudarte en cualquier momento.
          </p>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <p>
            !Hola <strong>{{ user?.dataSms?.names }}</strong>, {{user?.dataSms?.enterprise}}!
          </p>
          <p>
            Esperamos que te encuentres bien. Queremos recordarte que tu suscripción a nuestro servicio venció el <strong>{{user?.dataSms?.final_date }}</strong>. Nos encantaría que siguieras siendo parte de nuestra comunidad y que continúes disfrutando de todos los beneficios que ofrecemos.
          </p>
          <p>
            Sabemos que la vida puede ser ocupada y que a veces se nos pasan las fechas importantes. Pero ¡no te preocupes! Todavía estás a tiempo para renovar tu suscripción y recuperar el acceso completo a nuestra plataforma Guiia Tour.
          </p>
          <p>
            <ul class="list-detalle-plan">
              <li><strong>Plan:</strong> {{user?.dataSms?.namePlan}}</li>
              <li><strong>Precio:</strong> {{formatPrice(user?.dataSms?.price)}}</li>
              <li><strong>Total a pagar:</strong> {{formatPrice(user?.dataSms?.total)}}</li>
            </ul>
          </p>
          <p>
            <strong>Métodos de pago</strong>
            <ul>
              <li>Ahorros Bancolombia: 007-363929-17</li>
              <li>Nequi: 3007623748</li>
            </ul>
          </p>
          <p>
            <strong>Por favor</strong>, debes enviar una foto del comprobante de pago al <strong>300 7492396</strong>, para que podamos verificar el pago.
          </p>
          <p>
            ¡No dejes pasar esta oportunidad! Renueva ahora y sigue disfrutando de todos los beneficios que ofrecemos.
          </p>
          <p>
            Gracias por ser parte de nuestra comunidad.
          </p>
          <p>
            Atentamente, Equipo Guiia Tour
          </p>
          <p>
            Si tienes alguna pregunta o necesitas ayuda con el proceso de renovación, no dudes en ponerte en contacto con nuestro equipo de soporte al <strong>300 7492396</strong> Estamos aquí para ayudarte en cualquier momento.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { BModal, BIconX, BContainer, BRow, BCol } from "bootstrap-vue";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    BModal,
    BIconX,
    BContainer,
    BRow,
    BCol,
  },
  computed: {
    ...mapState("user", ["user","isModal"]),
  },
  methods: {
    ...mapActions("user", ["updateIsModal"]),
    async close() {
      const me = this;
      me.$bvModal.hide("renovation-plan-modal");
      me.updateIsModal(false);
    },
    formatPrice(value) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      return formatterPeso.format(value);
    }
  },
};
</script>

<style lang="css">
.container-message-renovation p ul {
  padding-left: 30px;
}
</style>
