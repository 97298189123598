<template>
  <b-modal id="session-finish-modal" hide-footer centered size="sm">
    <template v-slot:modal-header>
      <h5>Sesión Finalizada</h5>
      <button class="btn__close" @click="close()">
        <b-icon-x font-scale="2" />
      </button>
    </template>
    <b-container>
      <b-row>
        <b-col cols="12">
          <label for="name" class="text__ mb-0">
            Tienes otra sesión activa en otro dispositivo.
          </label>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { BModal, BIconX, BContainer, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BIconX,
    BContainer,
    BRow,
    BCol,
  },
  methods: {
    async close() {
      const me = this;
      me.$bvModal.hide("session-finish-modal");
      localStorage.clear();
      me.$router.push("/login");
    },
  },
};
</script>

<style></style>
