<template>
  <b-modal id="expired-token-model" hide-footer centered size="sm">
    <template v-slot:modal-header>
      <h5>Sesión caducada</h5>
      <button class="btn__close" @click="close()">
        <b-icon-x font-scale="2" />
      </button>
    </template>
    <b-container>
      <b-row>
        <b-col cols="12">
          <label for="name" class="text__ mb-0">
            Tú sesión ha caducado, vuelve a iniciar sesión.
          </label>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { BModal, BIconX, BContainer, BRow, BCol } from "bootstrap-vue";
import { mapGetters } from "vuex";

export default {
  inject: ["authRepository"],
  components: {
    BModal,
    BIconX,
    BContainer,
    BRow,
    BCol,
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
  },
  methods: {
    async close() {
      const me = this;
      await me.logout();
      me.$bvModal.hide("expired-token-model");
      localStorage.clear();
      me.$router.push("/login");
    },
    async logout() {
      const me = this;
      try {
        await me.authRepository.logout({
          username: me.getUser.username,
        });
      } catch (error) {
        // console.log(error);
      }
    },
  },
};
</script>

<style></style>
