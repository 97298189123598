export default {
  methods: {
    messageSuccess(title = "Registro guardado", message = "Registro guardado con exito") {
      const me = this;
      const h = me.$createElement;
      const messageVNode = h("div", { class: ["foobar"] }, [
        h("p", { class: ["text-center", "title-message mt-2 mb-1"] }, [`${title}`]),
        h("p", { class: ["icon-message-success"] }),
        h("p", { class: ["text-center mr-3 ml-3", "description-message"] }, [
          `${message}`,
        ]),
      ]);
      me.$bvModal.msgBoxOk([messageVNode], {
        title: "",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "Ok",
        cancelButton: false,
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      });
    },
    messageError(title = "Error al guardar registro", message = "Error") {
      const me = this;
      const h = me.$createElement;
      const messageVNode = h("div", { class: ["foobar"] }, [
        h("p", { class: ["text-center", "title-message mt-2 mb-1"] }, [`${title}`]),
        h("p", { class: ["icon-message-error"] }),
        h("p", { class: ["text-center mr-3 ml-3", "description-message"] }, [
          `${message}`,
        ]),
      ]);
      me.$bvModal.msgBoxOk([messageVNode], {
        title: "",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "Ok",
        cancelButton: false,
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      });
    },
  },
};
