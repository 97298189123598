<template>
  <div>
    <Header />
    <b-container fluid class="container__login">
      <button-wpp />
      <div class="login__content">
        <img src="@/assets/images/logologin.png" alt="" class="w-100 mb-3" />
        <h2 class="title__login">Inicio de sesión</h2>
        <div
          class="panel__msg--error d-flex justify-content-between"
          v-if="statusMsgError"
        >
          <small class="msg__error mt-1">{{ msgError }}</small>
          <b-button class="btn btn__close--panel" @click="closedPanel">
            <b-icon-x />
          </b-button>
        </div>
        <b-form @submit.prevent="login" autocomplete="off">
          <div id="usernama">
            <div class="input__field">
              <span class="icono__input">
                <b-icon-person-fill />
              </span>
              <input
                type="text"
                placeholder="Usuario"
                v-model="user.username"
                autofocus
              />
            </div>
          </div>
          <div id="password">
            <div class="input__field">
              <span class="icono__input">
                <b-icon-lock-fill />
              </span>
              <input
                :type="type"
                placeholder="Contraseña"
                v-model="user.password"
              />
              <span class="icon_eye" @click.prevent="seePassword">
                <b-icon-eye-fill v-if="type == 'password'" />
                <b-icon-eye-slash-fill v-else />
              </span>
            </div>
          </div>
          <div class="olvidar__password">
            <a class="a__login" @click="recoveryPasswod"
              >¿Olvidaste tú contraseña?</a
            >
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              type="submit"
              class="btn__ingresar mt-3 mb-2"
              :disabled="isBusy || $v.$invalid"
            >
              <b-spinner class="mr-2" small v-if="isBusy" />
              Ingresar
            </b-button>
          </div>
        </b-form>
      </div>
      <base-modal id="recovery-password" :title="title" :size="'md'">
        <template v-slot:form>
          <message-recovery v-if="isMessage" />
          <recover-password-form @sendEmail="sendEmail" v-else />
        </template>
      </base-modal>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import {
  BIconPersonFill,
  BIconLockFill,
  BIconEyeFill,
  BIconEyeSlashFill,
  BIconX,
  BButton,
  BContainer,
  BForm,
  BSpinner,
} from "bootstrap-vue";
import BaseModal from "@/components/base/BaseModal";
import RecoverPasswordForm from "./modal/RecoverPasswordForm";
import MessageRecovery from "./modal/MessageRecovery";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import messageMixin from "@/mixins/messageMixin";
import Header from '@/app/home/components/Header';
import Footer from '@/app/home/components/Footer';
import ButtonWpp from "@/components/buttons/ButtonWpp";


export default {
  inject: ["authRepository"],
  mixins: [messageMixin],
  name: "Login",
  components: {
    BIconPersonFill,
    BIconLockFill,
    BIconEyeFill,
    BIconEyeSlashFill,
    BIconX,
    BButton,
    BContainer,
    BForm,
    BSpinner,
    BaseModal,
    RecoverPasswordForm,
    MessageRecovery,
    Header,
    Footer,
    ButtonWpp
  },

  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      statusMsgError: false,
      msgError: "",
      isBusy: false,
      isMessage: false,
      title: "Recuperar contraseña",
      type: "password",
    };
  },

  validations: {
    user: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },

  methods: {
    ...mapActions("user", ["loadUser"]),
    async login() {
      const me = this;
      try {
        me.isBusy = true;
        me.user.username = me.user.username.trim();
        me.user.password = me.user.password.trim();
        
        let { data  } = await me.authRepository.login(me.user);

        me.statusMsgError = false;
        await me.$store.dispatch("user/loadUser", data);

        window.location.href = "/dashboard";
      } catch (error) {
        if (error.data) {
          const { message } = error.data;

          if (message == "User not registered") {
            me.msgError = "Usuario no registrado.";
            me.statusMsgError = true;
          } else if (message == "Session active") {
            me.$bvModal
              .msgBoxConfirm(
                "Actualmente cuentas con una sesión activa en otro dispositivo ¿Deseas cerrar la sesión, para poder iniciar en este dispositivo?",
                {
                  title: "Sesión activa",
                  size: "sm",
                  buttonSize: "sm",
                  okVariant: "danger",
                  okTitle: "Si",
                  cancelTitle: "No",
                  footerClass: "p-2",
                  hideHeaderClose: false,
                  centered: true,
                }
              )
              .then(async (value) => {
                if (value) {
                  me.logout();
                }
              });
          } else if (message == "Enterprise inactive") {
            me.messageError("Subscripción vencida", "La empresa se encuentra inactiva. Tu subscripción se encuentra vencida. Te invitamos a realizar la renovación. Si tienes alguna pregunta o necesitas ayuda con el proceso de renovación, no dudes en ponerte en contacto con nuestro equipo de soporte al (300 7492396). Estamos aquí para ayudarte en cualquier momento.");
          } else {
            me.msgError = "Usuario ó contraseña incorrectos.";
            me.statusMsgError = true;
          }
        } else {
          me.msgError = "Ocurrio un error. Intentalo nuevamente.";
          me.statusMsgError = true;
        }
      } finally {
        me.isBusy = false;
      }
    },
    closedPanel() {
      const me = this;
      me.statusMsgError = false;
    },
    recoveryPasswod() {
      const me = this;
      me.isMessage = false;
      me.title = "Recuperar contraseña";
      me.$bvModal.show("recovery-password");
    },
    sendEmail() {
      const me = this;
      me.title = "¡Revisa tu bandeja de entrada!";
      me.isMessage = true;
    },
    async logout() {
      const me = this;
      try {
        await me.authRepository.logout({
          username: me.user.username,
        });
        me.login();
      } catch (error) {
        //console.log(error);
      }
    },
    seePassword() {
      const me = this;
      if (me.type == "password") {
        me.type = "text";
      } else {
        me.type = "password";
      }
    },
  },

  mounted() {
    window.scroll(0, 0);
  }
};
</script>

<style>
@import "./login.css";
</style>
